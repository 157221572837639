export const environment = {
  production: false,
  url: 'https://testapp.wisolar.com',
  apiUrl: 'https://testapp.wisolar.co/solar/api/',
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};
