<ul class="navbar-nav mr-auto">
  <li class="nav-item">
    <a class="nav-link navbar-icon sidebar-toggler" id="sidebar-toggler" href="javascript:;" (click)="toggleSidebar($event)">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </a>
  </li>

<!--  <li class="nav-item dropdown d-none d-sm-inline-block" ngbDropdown>-->
<!--    <a class="nav-link dropdown-toggle megamenu-link" href="javascript:;" ngbDropdownToggle>-->
<!--      <span>{{'HEADER.QUICK_LINK' | translate}}<i class="ti-angle-down arrow ml-2"></i></span>-->
<!--    </a>-->
<!--    <div class="dropdown-menu nav-megamenu" ngbDropdownMenu style="min-width: 400px">-->
<!--      <div class="row m-0">-->
<!--        <div class="col-6">-->
<!--          <a class="mega-menu-item" href="javascript:;"><i class="ft-star item-badge mb-4"></i>-->
<!--            <h5 class="mb-2">Season</h5>-->
<!--            &lt;!&ndash;<div class="text-muted font-12"></div>&ndash;&gt;-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <a class="mega-menu-item" href="javascript:;"><i class="ft-users item-badge mb-4"></i>-->
<!--            <h5 class="mb-2">Players</h5>-->
<!--            &lt;!&ndash;<div class="text-white font-12">Lorem Ipsum dolar.</div>&ndash;&gt;-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <a class="mega-menu-item" href="javascript:;"><i class="ft-activity item-badge mb-4"></i>-->
<!--            <h5 class="mb-2">Activity</h5>-->
<!--            &lt;!&ndash;<div class="text-muted font-12">Lorem Ipsum dolar.</div>&ndash;&gt;-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="col-6">-->
<!--          <a class="mega-menu-item" href="javascript:;"><i class="ft-play-circle item-badge mb-4"></i>-->
<!--            <h5 class="mb-2">Videos</h5>-->
<!--            &lt;!&ndash;<div class="text-muted font-12">All Highlights.</div>&ndash;&gt;-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </li>-->
</ul>
<ul class="navbar-nav">
<!--  <li class="nav-item">-->
<!--    <a class="nav-link navbar-icon" id="search-toggler" href="javascript:;" (click)="openSearchModal(searchModal)"><i class="ft-search"></i></a>-->
<!--  </li>-->
<!--  <li class="nav-item dropdown" ngbDropdown placement='bottom-right'>-->
<!--    <a class="nav-link dropdown-toggle navbar-icon" ngbDropdownToggle data-toggle="dropdown" href="javascript:;">-->
<!--      <i class="ft-bell position-relative"></i><span class="notify-signal bg-primary"></span>-->
<!--    </a>-->
<!--    <div class="dropdown-menu pt-0" style="min-width: 350px" ngbDropdownMenu>-->
<!--      <div class="py-4 px-3 text-center text-white mb-3" style="background-color: #2c2f48;">-->
<!--        <h5 class="m-0">7 New Notifications</h5>-->
<!--      </div>-->
<!--      <perfect-scrollbar style='max-height: 320px;position: relative;'>-->
<!--        <div class="list-group list-group-flush">-->
<!--          &lt;!&ndash;<a class="list-group-item list-group-item-action px-4 py-3" href="javascript:;">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media align-items-center"><i class="ti-shopping-cart text-center font-20 text-primary mr-3" style="width: 40px"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media-body">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="flexbox">&ndash;&gt;-->
<!--          &lt;!&ndash;<h6 class="mb-0 font-weight-bold">2 New Orders</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="text-muted font-13">15 min</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="font-13 text-muted">Lorem ipsum dolor sit amet ut.</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</a>&ndash;&gt;-->
<!--          &lt;!&ndash;<a class="list-group-item list-group-item-action px-4 py-3" href="javascript:;">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media align-items-center"><i class="ti-server text-center font-20 text-primary mr-3" style="width: 40px"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media-body">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="flexbox">&ndash;&gt;-->
<!--          &lt;!&ndash;<h6 class="mb-0 font-weight-bold">Server overloaded 91%</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="text-muted font-13">40 min</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="font-13 text-muted">Lorem ipsum dolor sit amet ut.</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</a>&ndash;&gt;-->
<!--          &lt;!&ndash;<a class="list-group-item list-group-item-action px-4 py-3" href="javascript:;">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media align-items-center"><i class="ti-support text-center font-20 text-primary mr-3" style="width: 40px"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media-body">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="flexbox">&ndash;&gt;-->
<!--          &lt;!&ndash;<h6 class="mb-0 font-weight-bold">New support request</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="text-muted font-13">1 hrs</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="font-13 text-muted">Lorem ipsum dolor sit amet ut.</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</a>&ndash;&gt;-->
<!--          &lt;!&ndash;<a class="list-group-item list-group-item-action px-4 py-3" href="javascript:;">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media align-items-center"><i class="ti-info-alt text-center font-20 text-primary mr-3" style="width: 40px"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media-body">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="flexbox">&ndash;&gt;-->
<!--          &lt;!&ndash;<h6 class="mb-0 font-weight-bold">System Warning</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="text-muted font-13">2 hrs</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="font-13 text-muted">Lorem ipsum dolor sit amet ut.</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</a>&ndash;&gt;-->
<!--          &lt;!&ndash;<a class="list-group-item list-group-item-action px-4 py-3" href="javascript:;">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media align-items-center"><i class="ti-email text-center font-20 text-primary mr-3" style="width: 40px"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media-body">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="flexbox">&ndash;&gt;-->
<!--          &lt;!&ndash;<h6 class="mb-0 font-weight-bold">You have 2 new messages</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="text-muted font-13">2 hrs</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="font-13 text-muted">Lorem ipsum dolor sit amet ut.</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</a>&ndash;&gt;-->
<!--          &lt;!&ndash;<a class="list-group-item list-group-item-action px-4 py-3" href="javascript:;">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media align-items-center"><i class="ti-user text-center font-20 text-primary mr-3" style="width: 40px"></i>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="media-body">&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="flexbox">&ndash;&gt;-->
<!--          &lt;!&ndash;<h6 class="mb-0 font-weight-bold">24 new users registered</h6>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="text-muted font-13">2 hrs</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;<div class="font-13 text-muted">Lorem ipsum dolor sit amet ut.</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</div>&ndash;&gt;-->
<!--          &lt;!&ndash;</a>&ndash;&gt;-->
<!--        </div>-->
<!--      </perfect-scrollbar>-->
<!--      <div class="px-3 py-2 text-center mt-3">-->
<!--        <a class="hover-link font-13" routerLink='/apps/mail'>view all</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </li>-->
  <!--<li class="nav-item" placement='bottom-right' ngbDropdown>-->
  <!--<a class="nav-link d-inline-flex align-items-center no-arrow" ngbDropdownToggle href="javascript:;">-->
  <!--<img src="{{_translationLoaderService.selectedLanguage.flag}}" width="24" alt="">-->
  <!--</a>-->
  <!--<div ngbDropdownMenu>-->
  <!--<a *ngFor="let lang of _translationLoaderService.languages" class="dropdown-item d-flex align-items-center" (click)="setLanguage(lang.lang)" href="javascript:;">-->
  <!--<img class="mr-3" src="{{lang.flag}}" width="24" alt="">{{lang.name}}-->
  <!--</a>-->
  <!--</div>-->
  <!--</li>-->
  <li class="nav-divider"></li>
  <li class="nav-item dropdown" ngbDropdown placement='bottom-right'>
    <a class="nav-link dropdown-toggle no-arrow d-inline-flex align-items-center" ngbDropdownToggle href="javascript:;">
      <span class="d-none d-sm-inline-block mr-2">{{(loggedInUser?.firstName ? loggedInUser?.firstName : '')}}</span>
      <img class="rounded-circle" src="assets/img/users/admin-image.png" alt="image" width="36" />
    </a>
    <div class="dropdown-menu pt-0 pb-4" ngbDropdownMenu style="min-width: 280px;">
      <div class="p-4 mb-4 media align-items-center text-white" style="background-color: #2c2f48;">
        <img class="rounded-circle mr-3" src="./assets/img/users/admin-image.png" alt="image" width="55" />
        <div class="media-body">
          <h5 class="mb-1">{{loggedInUser?.firstName + ' ' + loggedInUser?.lastName}}</h5>
          <div class="font-13">{{ loggedInUser?.roleName}}</div>
        </div>
      </div>
      <a class="dropdown-item d-flex align-items-center" routerLink="/account"><i class="ft-user mr-3 font-18 text-muted"></i>My Account</a>
      <!--<a class="dropdown-item d-flex align-items-center" href="javascript:;"><i class="ft-settings mr-3 font-18 text-muted"></i>Settings</a>-->
      <div class="dropdown-divider my-3"></div>
      <div class="mx-4">
        <a class="btn btn-link p-0" (click)="logOut()"><span class="btn-icon"><i class="ft-power mr-2 font-18"></i>Logout</span></a>
      </div>
    </div>
  </li>
  <!--<li class="nav-item">-->
  <!--<a class="nav-link navbar-icon" href="javascript:;" (click)="toggleQuickSidebar($event)"><i class="ti-align-right"></i></a>-->
  <!--</li>-->
</ul>

<ng-template #searchModal let-modal>
  <form class="search-top-bar" action="javascript:;">
    <input class="form-control search-input" type="text" placeholder="Search...">
    <button class="reset input-search-icon" type="submit"><i class="ft-search"></i></button>
    <button class="reset input-search-close" type="button" data-dismiss="modal" (click)="modal.dismiss()"><i class="ft-x"></i></button>
  </form>
</ng-template>
